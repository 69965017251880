.react-datepicker {
    border: 1px solid #d9b56e;
}

.react-datepicker__header {
    border-bottom: unset;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
}

.react-datepicker__day,
.react-datepicker__day:hover {
    border-radius: unset;
    outline: none;
}

.react-datepicker__navigation {
    outline: none;
}

.react-datepicker__day {
    margin: 0.166rem 0;
}

.react-datepicker__day--disabled {
    opacity: 0.4;
}

.react-datepicker__day--selected,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #d6b064;
}

.react-datepicker__day:hover,
.react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:hover,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: #d2a854;
    color: #ffffff;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
    background-color: #dfc084;
}

.react-datepicker__header {
    background-color: #e6e8eb;
}

.calendar--dark-mode,
.calendar--dark-mode .react-datepicker__time-container .react-datepicker__time {
    background-color: #333438;
    color: #ffffff;
}

.calendar--dark-mode
    .react-datepicker__month-container
    .react-datepicker__header,
.calendar--dark-mode
    .react-datepicker__time-container
    .react-datepicker__header {
    background-color: rgb(32, 33, 36);
}

.calendar--dark-mode
    .react-datepicker__time-container
    .react-datepicker__header
    .react-datepicker-time__header,
.calendar--dark-mode
    .react-datepicker__month-container
    .react-datepicker__header
    .react-datepicker__current-month,
.calendar--dark-mode
    .react-datepicker__month-container
    .react-datepicker__header
    .react-datepicker__day-names
    .react-datepicker__day-name,
.calendar--dark-mode
    .react-datepicker__month-container
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day {
    color: #ffffff;
}

.calendar--dark-mode .react-datepicker__navigation--next {
    border-left-color: #ffffff;
}

.calendar--dark-mode .react-datepicker__navigation--previous {
    border-right-color: #ffffff;
}
