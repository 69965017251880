@font-face {
    font-family: 'Aaux Next';
    src: url('/fonts/AauxNext-Thin.woff2') format('woff2'),
        url('/fonts/AauxNext-Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Aaux Next';
    src: url('/fonts/AauxNext-Light.woff2') format('woff2'),
        url('/fonts/AauxNext-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Aaux Next';
    src: url('/fonts/AauxNext-Regular.woff2') format('woff2'),
        url('/fonts/AauxNext-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aaux Next';
    src: url('/fonts/AauxNext-Medium.woff2') format('woff2'),
        url('/fonts/AauxNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aaux Next';
    src: url('/fonts/AauxNext-SemiBold.woff2') format('woff2'),
        url('/fonts/AauxNext-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Aaux Next';
    src: url('/fonts/AauxNext-Bold.woff2') format('woff2'),
        url('/fonts/AauxNext-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
